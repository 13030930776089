.article {
  cursor: pointer;
}

.articleTitle {
}

.article:hover .articleTitle {
  color: var(--bs-tertiary-color);
}

.articleSummary p {
  margin-bottom: 0;
}

.articleSummary {
  color: var(--bs-secondary-color);
}

.article:hover .articleSummary {
  color: var(--bs-tertiary-color);
}

.articleAuthor {
  color: var(--bs-secondary-color);
}

.article:hover .articleAuthor {
  color: var(--bs-tertiary-color);
}

@keyframes :global(flashBackground) {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: var(--bs-primary-bg-subtle);
  }
}

.flashArticle :global {
  animation: flashBackground 3s linear infinite;
}

.aTag {
  text-decoration: none;
  color: var(--bs-primary);
}

.aTag:hover {
  color: #007bff; /* Change to desired hover color */
  text-decoration: underline; /* Add underline on hover */
  cursor: pointer; /* Change cursor on hover */
}
