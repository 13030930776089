.dayTable tr:not(:last-child) > td:not([rowspan]) {
  border-color: var(--bs-secondary-bg);
}

.dayTable:hover td {
  background-color: var(--bs-tertiary-bg) !important;
  cursor: pointer;
  color: var(--bs-body-color) !important;
}

.stickyHeader th {
  position: sticky;
  top: 0;
  z-index: 1;
}
