.custom-dropdown-menu {
  width: 32px !important;
  max-width: 32px !important;
  min-width: 32px !important;
}
.custom-dropdown-menu.show {
  transform: translate(0px, 32px) !important;
}

.sticky-note {
  margin: 8px;
  padding: 8px;
  height: calc(100% - 16px);
}

.sticky-note a{
  color:   hsl( from var(--bs-secondary)  h 100% 40% );
  font-weight: bolder;
}

.article-summary p {
  margin-bottom: 0;
}

.note-yellow {
  background-color: #feff9c;
  color: var(--bs-black) !important;
}

.note-yellow-picker {
  background-color: #fdf748 !important;
  color: var(--bs-black) !important;
}

.note-yellow .table td {
  background-color: transparent !important;
  color: var(--bs-black) !important;
}

.note-blue {
  background-color: #b7e5e8;
  color: var(--bs-black) !important;
}

.note-blue-picker {
  background-color: #48b2e2 !important;
  color: var(--bs-black) !important;
}

.note-blue .table td {
  background-color: transparent !important;
  color: var(--bs-black) !important;
}

.note-green {
  background-color: #a3f9bd;
  color: var(--bs-black) !important;
}

.note-green-picker {
  background-color: #5efe8e !important;
  color: var(--bs-black) !important;
}

.note-green .table td {
  background-color: transparent !important;
  color: var(--bs-black) !important;
}

.note-orange {
  background-color: #faca93;
  color: var(--bs-black) !important;
}

.note-orange-picker {
  background-color: #f9ac54 !important;
  color: var(--bs-black) !important;
}

.note-orange .table td {
  background-color: transparent !important;
  color: var(--bs-black) !important;
}

.note-yellow .btn-link {
  color: var(--bs-black);
}

.note-blue .btn-link {
  color: var(--bs-black);
}

.note-green .btn-link {
  color: var(--bs-black);
}

.note-orange .btn-link {
  color: var(--bs-black);
}
