.settingsDropdown:after {
  display: none !important;
}

.topicList ul {
  list-style: none;
}

.topicList li {
  list-style: none;
}

.topicList {
  height: 80vh;
  overflow-y: scroll;
  padding-right: 2em;
}
