@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.exo-2-regular {
  font-family: 'Exo 2', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.exo-2-bold {
  font-family: 'Exo 2', sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.inter-regular {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: 'slnt' 0;
}

.inter-bold {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings: 'slnt' 0;
}

.nunito-sans-regular {
  font-family: 'Nunito Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    'wdth' 100,
    'YTLC' 500;
}

.nunito-sans-bold {
  font-family: 'Nunito Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    'wdth' 100,
    'YTLC' 500;
}
