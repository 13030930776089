.removeForecastButton {
  font-size: 0.8em;
  border-radius: 5px;
  padding: 1px;
  color: var(--bs-gold);
  background-color: var(--bs-secondary-bg);
}

.removeForecastButton:hover {
  background-color: var(--bs-tertiary-bg);
}

.forecast:focus {
}

.forecast {
  cursor: text;
}

.forecastEditor {
  border-radius: 0;
  //background-color: lighten($dark,5%);
  border: 1px solid var(--bs-gold);
  padding: 0;
  //color: $light;
  font-size: 1em;
}

.emptyForecast {
  //background-color: lighten($dark,10%);
  background-color: var(--bs-secondary-bg);
}

.emptyForecast:after {
  content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0';
}
