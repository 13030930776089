.noProvider {
  background-color: var(--bs-secondary-bg);
  /* background:
            linear-gradient(to top left,
            rgba(0,0,0,0) 0%,
            rgba(0,0,0,0) calc(50% - 0.8px),
            var(--bs-gold) 50%,
            rgba(0,0,0,0) calc(50% + 0.8px),
            rgba(0,0,0,0) 100%);

    */
}
