.viewerToolbar {
  background-color: var(--bs-bg-gray);
}

.react-pdf__Outline {
  font-family: var(--bs-font-nunito-sans);

  ul {
    list-style: none;
    text-decoration: none;

    a {
      text-decoration: none;
      color: var(--bs-text-dark);
    }
  }
}
.react-pdf__Page__canvas {
  animation: fadeIn 0.5s;
  user-select: none; /* Standard syntax */
}

.react-pdf__Page__textContent {
  user-select: none; /* Standard syntax */
}

.hidden-scrollbar {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer & Edge */
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari & Opera */
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
