.bookmarkLink {
  color: var(--bs-body-color) !important;
  text-decoration: none !important;
  cursor: pointer;
}

.bookmarkLink:hover {
  color: var(--bs-light) !important;
  text-decoration: underline !important;
}
