// Colors
$primary-100: #f2f4f8;
$primary-200: #e2e7f1;
$primary-300: #b0caf1;
$primary-400: #5f9fff;
$primary-500: #1e75db;
$primary-600: #183ea1;
$primary-700: #070f66;
$tertiary-100: #fff3ea;
$tertiary-200: #fedd87;
$tertiary-300: #ffc01f;
$tertiary-400: #db7900;
$tertiary-500: #b35f00;
$tertiary-600: #974109;
$tertiary-700: #481a01;
$bg-light: #f2f4f8;
$bg-dark: #e2e7f1;
$bg-gray: #2e2e2e;
$bg-200: #d9d9d9;
$dark-bg-300: #5c5c5c;
$neutral-100: #f3f3f3;
$neutral-200: #d9d9d9;
$neutral-300: #bcbcbc;
$neutral-400: #949494;
$neutral-500: #696969;
$neutral-600: #3b3b3b;
$text-dark: #010428;
$base-dark: #000000;
$base-light: #ffffff;
$error: #c9544e;
$success: #8dcb82;
$warning: #e5c10b;
$info: #88a8d9;
$pills-blue: #2f367e;
$pills-orange: #e09741;

$custom-colors: (
  'primary-100': $primary-100,
  'primary-200': $primary-200,
  'primary-300': $primary-300,
  'primary-400': $primary-400,
  'primary-500': $primary-500,
  'primary-600': $primary-600,
  'primary-700': $primary-700,
  'tertiary-100': $tertiary-100,
  'tertiary-200': $tertiary-200,
  'tertiary-300': $tertiary-300,
  'tertiary-400': $tertiary-400,
  'tertiary-500': $tertiary-500,
  'tertiary-600': $tertiary-600,
  'tertiary-700': $tertiary-700,
  'tertiary': $tertiary-400,
  'neutral-100': $neutral-100,
  'neutral-200': $neutral-200,
  'neutral-300': $neutral-300,
  'neutral-400': $neutral-400,
  'neutral-500': $neutral-500,
  'neutral-600': $neutral-600,
  'text-light': $neutral-500,
  'text-dark': $text-dark,
  'bg-dark': $bg-dark,
  'bg-light': $bg-light,
  'bg-gray': $bg-gray,
  'bg-200': $bg-200,
  'bg-300': $dark-bg-300,
  'pills-blue': $pills-blue,
  'pills-orange': $pills-orange,
  'error': $error,
);

$theme-colors: (
  'primary': $primary-700,
  'secondary': $primary-400,
  'success': $success,
  'danger': $error,
  'warning': $warning,
  'info': $info,
  'base-dark': $base-dark,
  'base-light': $base-light,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

@import 'fonts';

// Typography
$font-family-sans-serif: 'Nunito Sans', 'Open Sans', sans-serif;
$headings-font-family: 'Exo 2', sans-serif;
$headings-font-weight: 700;
$headings-color: $primary-700;

$small-font-size: 0.75em;

$h1-font-size: 3rem;
$h2-font-size: 2rem;
$h3-font-size: 1.25rem;
$h4-font-size: 1rem;
$h5-font-size: 0.875rem;
$h6-font-size: 0.75rem;

$font-sizes: (
  1: 3rem,
  2: 1.5rem,
  3: 1.25rem,
  4: 1rem,
  5: 0.875rem,
  6: 0.75rem,
);

$body-color: $text-dark;
$body-bg: $base-light;

// Switch component classes
$form-check-input-bg: $neutral-100;
$form-check-input-border: 1px solid $neutral-300;
$form-check-input-focus-box-shadow: none;

// Checkbox component classes
$form-check-input-checked-color: $tertiary-400;
$form-check-input-checked-border-color: $tertiary-400;
$form-check-input-checked-bg-color: $neutral-100;

// Tooltip component classes
$tooltip-color: $base-light;
$tooltip-bg: $primary-700;
$tooltip-border-radius: 0.3rem;
$tooltip-padding-y: 0.438rem;
$tooltip-padding-x: 0.563rem;

// Badge component classes
$badge-font-size: 0.875em;
$badge-font-weight: 400;
$badge-border-radius: 3.125rem;

// Input component classes
$input-placeholder-color: $neutral-300;
$input-border-color: $neutral-300;
$input-padding-y: 0.625rem;
$input-padding-x: 0.5rem;
$input-border-radius: 0.25rem;
$input-focus-border-color: $primary-700;
$input-focus-box-shadow: none;
$input-disabled-bg: $neutral-100;
$input-disabled-border-color: $neutral-300;

$form-validation-states: (
  'invalid': (
    'color': $error,
    'icon': none,
    'focus-box-shadow': none,
  ),
);

// Radio component classes
$form-check-radio-checked-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='#{$tertiary-400}'/%3e%3c/svg%3e");

$my-breakpoint: '1000px';
