.answerYes {
  color: #9af39a;
  font-weight: bold;
}

.answerNo {
  color: #f58686;
  font-weight: bold;
}

.answerNA {
  color: #a1a1ff;
}

.data-table {
  font-size: 0.5em;
}
