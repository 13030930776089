.dropdownButton {
  :global(.dropdown-menu) {
    width: 300px;
    white-space: normal;
    height: calc(100vh - 56px);
    margin-top: 10px;
    overflow-y: auto;
    border-radius: 0;
    border: none;
    transform: none !important;
    position: fixed !important;
    right: 0 !important;
    left: auto !important;
    top: 46px !important;
  }

  :global(.dropdown-item) {
    word-wrap: break-word;
    white-space: normal;
  }
  .menuItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 60px;
  }
}
