@import './src/index.scss';

.weekEvent {
  border-bottom: 2px dotted #545454;
  padding-bottom: 0.2rem;
  border-radius: 0.2rem;
  margin: 0.5rem;
  word-break: break-word;
  cursor: pointer;
}

.weekEventTitle {
}

.weekEventTitle:hover {
  color: var(--bs-body-color) !important;
}

.dayColumn:hover {
  background-color: var(--bs-tertiary-bg);
}

.dayColumn:last-child {
  border-bottom: none !important;
}

.weekHeader {
  background-color: var(--bs-quaternary-bg);
  border-bottom: 2px solid var(--bs-secondary-bg);
  border-top: 1px solid var(--bs-secondary-bg);
  padding-left: 0.5rem;
}
