.dashboardDrawer {
  background-color: var(--bs-body-bg);
}

.dashboardDrawerOpen {
  width: 17em;
  animation: open 0.15s ease-out;
}

.dashboardDrawerClosed {
  width: 0;
  animation: close 0.2s ease-out;
}

@keyframes open {
  0% {
    width: 0;
    opacity: 0;
    overflow: hidden;
  }

  100% {
    width: 17em;
    opacity: 100%;
  }
}

@keyframes close {
  0% {
    width: 17em;
  }

  100% {
    width: 0;
  }
}

.dashboardDrawerTab {
  top: 100px;
  left: -20px;
  border-bottom-left-radius: 1em;
  border-top-left-radius: 1em;
  width: 20px;
  height: 100px;
  background-color: var(--bs-body-bg);
  cursor: pointer;
}

.dashboardDrawerTab:hover {
  background-color: var(--bs-secondary-bg);
}

.dashboardDrawerContent {
}

.dashboardDrawerContent > div {
  overflow: auto;
  height: 100%;
}
