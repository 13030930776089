.flash {
}

.flash::after {
  z-index: 10000;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0); /* Change the color and opacity as needed */
  animation: flash 1.5s 3;
  pointer-events: none;
}

.widgetPanel {
}

.widgetPanelHeader {
}

.widgetPanel:hover .widgetPanelHeader {
  color: var(--bs-secondary);
}

@keyframes flash {
  0% {
    background-color: rgba(1, 1, 1, 0);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  100% {
    background-color: rgba(1, 1, 1, 0);
  }
}
