.divider-with-text {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 1rem 0;
}

.divider-with-text .line {
  flex: 1;
  border: none;
  border-top: 1px solid #ccc; /* Customize color as needed */
  margin: 0 0.5rem;
}

.divider-text {
  font-weight: bold;
}

[data-bs-theme='light'] {
  .suggestion-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  }

  .suggestion-card.selected {
    background-color: greenyellow;
  }
}

[data-bs-theme='dark'] {
  .suggestion-card:hover {
    box-shadow: 0px 4px 12px rgba(255, 255, 255, 0.2);
  }

  .suggestion-card.selected {
    background-color: yellowgreen;
    color: black;
  }
}
