[data-bs-theme='light'] {
  .dashboardPanelArea {
    background-color: var(--bs-secondary-bg);
  }
}

[data-bs-theme='dark'] {
  .dashboardPanelArea {
    background-color: #25232c;
  }
}

.dashboardPlaceholder {
  background: linear-gradient(
    to bottom,
    var(--bs-body-bg) 00%,
    var(--bs-body-bg) 60%,
    color-mix(in srgb, var(--bs-body-bg), black 20%) 99%
  );
}
