@import 'variables';

@import 'bootstrap/scss/bootstrap';

body {
  margin: 0;
  font-family:
    'Nunito Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --diff-positive-color: var(--bs-tertiary);
  --diff-negative-color: var(--bs-secondary);

  user-select: none;
  -webkit-user-select: none;

  .flag-image {
    display: inline;
  }

  .flag-text {
    display: none;
  }
}

[data-flag-display='text'] {
  .flag-image {
    display: none;
  }

  .flag-text {
    display: inline;
  }
}

[data-diff-theme='orange-blue'] {
  --diff-positive-color: var(--bs-tertiary);
  --diff-negative-color: var(--bs-secondary);
}

[data-diff-theme='red-green'] {
  --diff-positive-color: var(--bs-success);
  --diff-negative-color: var(--bs-error);
}

[data-diff-theme='yellow-purple'] {
  --diff-positive-color: var(--bs-pills-orange);
  --diff-negative-color: var(--bs-pills-blue);
}

:root {
  --bs-quaternary-bg: var(--bs-bg-dark);
  --bs-gold: #988b5f;

  --mni-d-inline-hide-in-dock: inline;
  --mni-d-block-hide-in-dock: block;
  --mni-d-flex-hide-in-dock: flex;
}

[data-bs-theme='light'] {
  --bs-quaternary-bg: var(--bs-bg-dark);
  --bs-gold: #383322;

  .navbar {
    background-color: var(--bs-primary);
    background-image: none;
  }

  .dashboard-container {
    background-color: var(--bs-bg-light);
  }

  .chevron {
    border: none !important;
    color: var(--bs-primary);
  }
}

.text-gold {
  color: var(--bs-gold, #988b5f) !important;
}

.bg-quaternary {
  background-color: var(--bs-quaternary-bg) !important;
}

.diff-negative {
  color: var(--diff-negative-color);
}

.diff-positive {
  color: var(--diff-positive-color);
}

.btn-menu:hover {
  background-color: var(--bs-secondary-bg) !important;
}

.btn-menu:active {
  background-color: var(--bs-tertiary-bg) !important;
}

.dropdown-item:active {
  background-color: transparent;
  color: black;
  font-weight: bold;
}

.mni-dock {
  --mni-d-inline-hide-in-dock: none;
  --mni-d-block-hide-in-dock: none;
  --mni-d-flex-hide-in-dock: none;
}

.d-inline-hide-in-dock {
  display: var(--mni-d-inline-hide-in-dock) !important;
}

.d-block-hide-in-dock {
  display: var(--mni-d-block-hide-in-dock) !important;
}

.d-flex-hide-in-dock {
  display: var(--mni-d-flex-hide-in-dock) !important;
}

.responsive-text {
  font-size: calc(0.6em + 0.6vw);
}

.responsive-text-2 {
  font-size: calc(0.6em + 0.3vw);
}

::-webkit-scrollbar {
  width: 0.25em;
}

::-webkit-scrollbar-track {
}

::-webkit-scrollbar-thumb {
  background-color: #535353;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #363636;
}

.animatedBorder {
  padding: 3px;
  border-radius: 3px;
  background: blue;
  display: inline-block;

  background: linear-gradient(218deg, $error, $info);
  background-size: 400% 400%;

  -webkit-animation: glow 5s ease infinite;
  -moz-animation: glow 5s ease infinite;
  animation: glow 5s ease infinite;
}

@-webkit-keyframes glow {
  0% {
    background-position: 82% 0%;
  }
  50% {
    background-position: 19% 100%;
  }
  100% {
    background-position: 82% 0%;
  }
}
@-moz-keyframes glow {
  0% {
    background-position: 82% 0%;
  }
  50% {
    background-position: 19% 100%;
  }
  100% {
    background-position: 82% 0%;
  }
}
@keyframes glow {
  0% {
    background-position: 82% 0%;
  }
  50% {
    background-position: 19% 100%;
  }
  100% {
    background-position: 82% 0%;
  }
}

.container-element {
  container-type: inline-size;
  container-name: container;

  .d-container-none {
    display: none !important;
  }
  .d-container-block {
    display: block !important;
  }
  .d-container-inline {
    display: inline !important;
  }

  .flex-container-column {
    flex-direction: column !important;
  }

  @container container (max-width: 449px) {
    .hide-xxs {
      display: none !important;
    }
  }

  @container container (min-width: 0px) {
    .container-responsive-text {
      font-size: calc(0.7em + 0.7cqw) !important;
    }

    .container-responsive-text-sm {
      font-size: calc(0.6em + 0.6cqw) !important;
    }

    h4 {
      font-size: calc(1.275rem + 0.3cqw) !important;
    }
  }

  @container container (min-width: 449px) {
    .date-picker {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  @container container (min-width: 576px) {
    .d-container-sm-none {
      display: none !important;
    }

    .d-container-sm-block {
      display: block !important;
    }

    .d-container-sm-inline {
      display: inline !important;
    }

    .flex-container-sm-row {
      flex-direction: row !important;
    }

    .text-container-sm-start {
      text-align: left !important;
    }

    .col-container-sm-8 {
      flex: 0 0 auto !important;
      width: 66.66666667% !important;
    }

    .offset-container-sm-2 {
      margin-left: 16.66666667% !important;
    }
  }

  @container container (min-width: 768px) {
    .d-container-md-inline {
      display: inline !important;
    }
    .d-container-md-none {
      display: none !important;
    }
    .col-container-md-1 {
      flex: 0 0 auto !important;
      width: 8.33333333% !important;
    }
    .col-container-md-2 {
      flex: 0 0 auto !important;
      width: 16.66666667% !important;
    }
  }
}

@import 'variables-dark';
