// Dark color mode variables
//
// Global colors
//

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

$dark-bg-light: #010428;
$dark-bg-dark: #e2e7f1;
$dark-bg-gray: #2e2e2e;
$dark-bg-200: #d9d9d9;
$dark-bg-300: #5c5c5c;
$dark-primary-100: #1f1e26;
$dark-primary-200: #21242b;
$dark-primary-300: #3d3f42;
$dark-primary-400: #5f9fff;
$dark-primary-500: #0094ff;
$dark-primary-600: #183ea1;
$dark-primary-700: #0c0b10;
$dark-neutral-100: #2c3142;
$dark-neutral-200: #14192b;
$dark-neutral-300: #bcbcbc;
$dark-neutral-400: #949494;
$dark-neutral-500: #696969;
$dark-neutral-600: #a1a6c1;
$dark-tertiary-100: #fff3ea;
$dark-tertiary-200: #fedd87;
$dark-tertiary-300: #ffc01f;
$dark-tertiary-400: #ff8d00;
$dark-tertiary-500: #ff8700;
$dark-tertiary-600: #974109;
$dark-tertiary-700: #481a01;
$dark-text-dark: #ceced1;
$dark-base-dark: #ceced1;
$dark-base-light: #151419;
$dark-error: #c9544e;
$dark-success: #8dcb82;
$dark-warning: #e5c10b;
$dark-info: #88a8d9;
$dark-pills-blue: #2f367e;
$dark-pills-orange: #e09741;

[data-bs-theme='dark'] {
  $custom-colors: (
    'primary-100': $dark-primary-100,
    'primary-200': $dark-primary-200,
    'primary-300': $dark-primary-300,
    'primary-400': $dark-primary-400,
    'primary-500': $dark-primary-500,
    'primary-600': $dark-primary-600,
    'primary-700': $dark-primary-700,
    'tertiary-100': $dark-tertiary-100,
    'tertiary-200': $dark-tertiary-200,
    'tertiary-300': $dark-tertiary-300,
    'tertiary-400': $dark-tertiary-400,
    'tertiary-500': $dark-tertiary-500,
    'tertiary-600': $dark-tertiary-600,
    'tertiary-700': $dark-tertiary-700,
    'tertiary': $dark-tertiary-400,
    'neutral-100': $dark-neutral-100,
    'neutral-200': $dark-neutral-200,
    'neutral-300': $dark-neutral-300,
    'neutral-400': $dark-neutral-400,
    'neutral-500': $dark-neutral-500,
    'neutral-600': $dark-neutral-600,
    'text-light': $dark-neutral-500,
    'text-dark': $dark-text-dark,
    'bg-dark': $dark-bg-dark,
    'bg-light': $dark-bg-light,
    'bg-gray': $dark-bg-gray,
    'bg-200': $dark-bg-200,
    'bg-300': $dark-bg-300,
    'pills-blue': $dark-pills-blue,
    'pills-orange': $dark-pills-orange,
    'error': $dark-error,
  );

  $theme-colors: (
    'primary': $dark-primary-700,
    'secondary': $dark-primary-500,
    'success': $dark-success,
    'warning': $dark-warning,
    'info': $dark-info,
    'base-light': $dark-base-light,
    'base-dark': $dark-base-dark,
  );

  $theme-colors: map-merge($theme-colors, $custom-colors);

  /* redefine theme color variables */
  @each $color, $value in $theme-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  $theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');

  @each $color, $value in $theme-colors-rgb {
    --#{$variable-prefix}#{$color}-rgb: #{$value};
  }

  $body-color: $dark-text-dark;
  $body-bg: $dark-base-light;

  --#{$variable-prefix}quaternary-bg: #{$dark-primary-200};
  --#{$variable-prefix}gold: #988b5f;
  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-bg: #{$body-bg};

  .responsive-layout.react-grid-item > .react-resizable-handle::after {
    border-right: 2px solid $dark-text-dark;
    border-bottom: 2px solid $dark-text-dark;
  }

  .navbar {
    background-color: $dark-base-light !important;
    background-image: none !important;
  }

  .dashboard-container {
    background-color: $dark-primary-200;
  }

  .chevron {
    border: none !important;
    color: var(--bs-neutral-300) !important;
  }

  .chevron-light {
    border: none !important;
    color: var(--bs-primary) !important;
  }
  .chevron-light:hover {
    color: var(--bs-neutral-300) !important;
  }
  //   scss-docs-start sass-dark-mode-vars
  //   scss-docs-start theme-text-dark-variables
  $primary-text-emphasis-dark: tint-color($dark-primary-700, 40%);
  $secondary-text-emphasis-dark: tint-color($dark-primary-500, 40%);
  $success-text-emphasis-dark: tint-color($dark-success, 40%);
  $info-text-emphasis-dark: tint-color($dark-info, 40%);
  $warning-text-emphasis-dark: tint-color($dark-warning, 40%);
  $danger-text-emphasis-dark: tint-color($dark-error, 40%);
  // scss-docs-end theme-text-dark-variables

  // scss-docs-start theme-bg-subtle-dark-variables
  $primary-bg-subtle-dark: shade-color($dark-primary-700, 80%);
  $secondary-bg-subtle-dark: shade-color($dark-primary-500, 80%);
  $success-bg-subtle-dark: shade-color($dark-success, 80%);
  $info-bg-subtle-dark: shade-color($dark-info, 80%);
  $warning-bg-subtle-dark: shade-color($dark-warning, 80%);
  $danger-bg-subtle-dark: shade-color($dark-error, 80%);
  // scss-docs-end theme-bg-subtle-dark-variables

  // scss-docs-start theme-border-subtle-dark-variables
  $primary-border-subtle-dark: shade-color($dark-primary-700, 40%);
  $secondary-border-subtle-dark: shade-color($dark-primary-500, 40%);
  $success-border-subtle-dark: shade-color($dark-success, 40%);
  $info-border-subtle-dark: shade-color($dark-info, 40%);
  $warning-border-subtle-dark: shade-color($dark-warning, 40%);
  $danger-border-subtle-dark: shade-color($dark-error, 40%);
  // scss-docs-end theme-border-subtle-dark-variables

  $body-color-dark: $dark-text-dark;
  $body-bg-dark: $dark-base-light;
  $body-secondary-color-dark: rgba($body-color-dark, 0.75);
  $body-secondary-bg-dark: $dark-primary-100;
  $body-tertiary-color-dark: rgba($body-color-dark, 0.5);
  $body-emphasis-color-dark: $dark-base-light;
  $border-color-dark: $dark-neutral-500;
  $border-color-translucent-dark: rgba($dark-base-light, 0.15);
  $headings-color-dark: inherit;

  // Switch component classes
  $form-check-input-bg: $dark-neutral-100;
  $form-check-input-border: 1px solid $dark-neutral-300;
  $form-check-input-focus-box-shadow: none;

  // Checkbox component classes
  $form-check-input-checked-color: $dark-tertiary-400;
  $form-check-input-checked-border-color: $dark-tertiary-400;
  $form-check-input-checked-bg-color: $dark-neutral-100;

  // Tooltip component classes
  $tooltip-color: $body-color-dark;
  $tooltip-bg: $dark-primary-700;
  $tooltip-border-radius: 0.3rem;
  $tooltip-padding-y: 0.438rem;
  $tooltip-padding-x: 0.563rem;

  // Badge component classes
  $badge-font-size: 0.875em;
  $badge-font-weight: 400;
  $badge-border-radius: 3.125rem;

  // Input component classes
  $input-placeholder-color: $dark-neutral-400;
  $input-border-color: $dark-neutral-300;
  $input-padding-y: 0.625rem;
  $input-padding-x: 0.5rem;
  $input-border-radius: 0.25rem;
  $input-focus-border-color: $dark-neutral-300;
  $input-focus-box-shadow: none;
  $input-disabled-bg: $dark-neutral-100;
  $input-disabled-border-color: $dark-neutral-400;

  $form-select-focus-border-color: $dark-neutral-300;

  $form-validation-states: (
    'invalid': (
      'color': $dark-error,
      'icon': none,
      'focus-box-shadow': none,
    ),
  );

  $form-check-radio-checked-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='#{$dark-tertiary-400}'/%3e%3c/svg%3e");

  @import 'bootstrap/scss/bootstrap';
}
