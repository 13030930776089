@import '/src/variables';

.settingsDropdown:after {
  display: none !important;
}

.customBadge {
  color: var(--bs-secondary);
  background-color: var(--bs-secondary-bg);
  border-radius: 0.2em;
  padding: 0.2em;
}
