@import '../../index';

.hotEvent {
  cursor: pointer;
}

.hotEvent:hover {
  color: white;
}

.hotEvent td {
  border-color: lighten($dark, 10%);
}
