.animatedBorder {
  background: blue;

  background: linear-gradient(218deg, var(--bs-warning), var(--bs-primary));
  background-size: 400% 400%;

  -webkit-animation: glow 5s ease infinite;
  -moz-animation: glow 5s ease infinite;
  animation: glow 5s ease infinite;
}

@-webkit-keyframes glow {
  0% {
    background-position: 82% 0%;
  }
  50% {
    background-position: 19% 100%;
  }
  100% {
    background-position: 82% 0%;
  }
}
@-moz-keyframes glow {
  0% {
    background-position: 82% 0%;
  }
  50% {
    background-position: 19% 100%;
  }
  100% {
    background-position: 82% 0%;
  }
}
@keyframes glow {
  0% {
    background-position: 82% 0%;
  }
  50% {
    background-position: 19% 100%;
  }
  100% {
    background-position: 82% 0%;
  }
}
